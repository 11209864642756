/* global $ */

class Login
{
    constructor ()
    {
        $('form.confirmPassword').submit(this.confirmPassword);

        console.log('Login initialised');
    }

    confirmPassword()
    {
        const newPassword = $('#newPassword'),
            confirmPassword = $('#confirmPassword');

        if (newPassword.val() !== confirmPassword.val()) {
            confirmPassword.after('<div class="small errors text-danger">The passwords don\'t match.</div>');
            return false;
        }
    }
}

new Login();
